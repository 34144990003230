import { ThirdPartyLogo, BrandedIcon } from '@mdb/flora';
import { TopicCardProps } from '@mdb/devcenter-components';
import { palette } from '@leafygreen-ui/palette';
import SparkleIcon from '@leafygreen-ui/icon/dist/Sparkle';
import { iconStyles } from '../components/topic-cards-container/styles';
import { technologyToLogo } from './technology-to-logo';
import { productToLogo } from './product-to-logo';
import { languageToLogo } from './language-to-logo';
import { Tag } from '../interfaces/tag';
import { getURLPath } from './format-url-path';
import theme from '@mdb/flora/theme';
import Image from 'next/image';

export const tagToTopic = (tag: Tag): TopicCardProps => {
    let icon: JSX.Element | null = null;
    const href = tag.slug ? getURLPath(tag.slug) : undefined;

    if (tag.type === 'Technology' && tag.name === 'Serverless') {
        icon = <BrandedIcon sx={iconStyles} name="atlas_serverless" />;
    } else if (tag.type === 'Technology' && tag.name === 'Mobile') {
        icon = <BrandedIcon sx={iconStyles} name="misc_mobile" />;
    } else if (tag.type === 'Technology' && tag.name === 'AI') {
        // TODO: Change to BrandedIcon once available in brand assets
        icon = <SparkleIcon sx={iconStyles} fill={palette.green.dark1} />;
    } else if (tag.type === 'Technology' && tag.name === 'Neurelo') {
        icon = (
            <Image
                src="https://images.contentstack.io/v3/assets/blt39790b633ee0d5a7/bltb9bd40008d8e63eb/65f9fa61ba94f053857d01e9/Neurelo_Icon_copy.png"
                width={theme.sizes.inc30}
                height={theme.sizes.inc30}
                alt="Neurelo"
            />
        );
    } else if (
        tag.type === 'Technology' ||
        tag.type === 'ProgrammingLanguage'
    ) {
        const iconName =
            tag.type === 'Technology'
                ? technologyToLogo[tag.name]
                : languageToLogo[tag.name];
        icon = iconName ? (
            <ThirdPartyLogo
                sx={iconStyles}
                variant={iconName}
                href={href}
                alt={iconName}
            />
        ) : null;
    } else if (tag.type === 'L1Product' || tag.type === 'L2Product') {
        const iconName = productToLogo[tag.name];
        icon = iconName ? (
            <BrandedIcon sx={iconStyles} name={iconName} />
        ) : null;
    }

    return {
        title: tag.name,
        icon,
        href,
    };
};
