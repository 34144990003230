import React from 'react';
import { UnifiedFooter } from '@mdb/consistent-nav';
import { enabledLocales, getCurrLocale, onSelectLocale } from '../utils/locale';

const Footer: React.FunctionComponent = () => {
    return (
        <UnifiedFooter
            onSelectLocale={onSelectLocale}
            locale={getCurrLocale()}
            enabledLocales={enabledLocales}
        />
    );
};

export default Footer;
