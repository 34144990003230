import { useContext, useEffect, useState } from 'react';
import { Global, css } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import { useSession } from 'next-auth/react';
import { UnifiedNav } from '@mdb/consistent-nav';
import { globalStyles, Main } from '../styled/layout';
import { ThemeUICSSObject } from 'theme-ui';
import SecondaryNav from './secondary-nav';
import { OverlayContext } from '../contexts/overlay';
import { layers } from '../styled/layout';
import { useEnsureImageAlts } from '../utils/seo';
import { useModalContext } from '../contexts/modal';
import { PaginatedPersonalizationModal } from './modal/personalization';
import getSignInURL from '../utils/get-sign-in-url';
import useUserPreferences from '../hooks/personalization/user-preferences';
import { Globals as FloraGlobals, Pill } from '@mdb/flora';
import Footer from './footer';
import { enabledLocales, getCurrLocale, onSelectLocale } from '../utils/locale';

const navStyles: ThemeUICSSObject = {
    zIndex: '9999',
    position: 'relative',
    'nav > div > div > ul': {
        zIndex: `${layers.desktopConsistentNavDropdown}!important`,
    }, // Needed so <ul /> list options from consistent nav displays over our secondary nav
    'div[role=menu-wrapper]': {
        zIndex: `${layers.mobileNavMenu}!important`,
    },
};
interface LayoutProps {
    pagePath?: string | null;
    isPathFactory?: boolean;
    banner?: BannerProps;
}

interface BannerProps {
    title: string;
    link: {
        url: string;
        text: string;
    };
    pillText: string;
}

const Banner: React.FunctionComponent<BannerProps> = ({
    title,
    link,
    pillText,
}) => (
    <div
        sx={{
            background: 'blue80',
            py: 'inc20',
            // px: '24px',
            fontFamily: 'Source Code Pro',
            height: '40px',
        }}
    >
        <div
            sx={{
                maxWidth: '1512px',
                margin: 'auto',
                height: '100%',
                px: ['24px', null, null, '48px'],
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                flexWrap: 'wrap',
            }}
        >
            <Pill
                sx={{
                    background: 'green30',
                    color: 'blue80',
                    fontSize: '9px',
                    px: '13px',
                    py: '2px',
                    letterSpacing: '2.5px',
                    display: ['none', null, 'inline-flex'],
                    border: '1px solid #001E2B',
                    lineHeight: '30px',
                    height: '24px',
                    alignItems: 'center',
                    marginRight: '16px',
                }}
                text={pillText}
                variant="identifier"
                color="secondary"
            />
            <span sx={{ color: 'white' }}>
                {title}
                <a
                    sx={{ color: 'blue50', marginLeft: '8px' }}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <span
                        sx={{ display: ['none', null, null, 'inline-block'] }}
                    >
                        {link.text}&nbsp;
                    </span>
                    <span>&gt;&gt;</span>
                </a>
            </span>
        </div>
    </div>
);

const Layout: React.FunctionComponent<LayoutProps> = ({
    children,
    pagePath,
    isPathFactory,
    banner,
}) => {
    const { updateUserPreferences } = useUserPreferences();
    const { hasOverlay } = useContext(OverlayContext);
    const { component: hasModalOpen, openModal } = useModalContext();
    const { data: session } = useSession();

    useEffect(() => {
        if (session && !session.lastLogin && !session.failedToFetch) {
            // Don't spam with the modal if we failed to fetch.
            openModal(
                <PaginatedPersonalizationModal />,
                // Pass default values to PUT if user dismisses the modal so their "lastLogin" flag can be updated
                {
                    onCloseCallback: () =>
                        updateUserPreferences({
                            followedTags: [],
                            emailPreference: false,
                        }),
                }
            );
        }
    }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

    const signInUrl = getSignInURL(pagePath);

    const isBooksPage = pagePath?.startsWith('/books/');

    // SEO workaround because unified nav/footer doesn't set their image alts properly
    const [layoutRef, setLayoutRef] = useState<HTMLElement | null>();
    useEnsureImageAlts(layoutRef);

    return (
        <div ref={ref => setLayoutRef(ref)}>
            <Global
                // TODO: using globalStyles as func call might be producing performance bottleneck
                styles={css`
                    ${emotionNormalize}
                    ${globalStyles(!!hasOverlay || !!hasModalOpen)}
                `}
            />
            <FloraGlobals />
            {!isPathFactory && !isBooksPage && (
                <>
                    {!!banner && <Banner {...banner} />}
                    <div sx={navStyles}>
                        <UnifiedNav
                            position="static"
                            floraTheme="default"
                            property={{ name: 'DEVHUB', searchParams: [] }}
                            hideTryFree={!!session}
                            hideSignIn={!!session}
                            signInUrl={signInUrl}
                            showLanguageSelector={true}
                            onSelectLocale={onSelectLocale}
                            locale={getCurrLocale()}
                            enabledLocales={enabledLocales}
                        />
                    </div>
                    <SecondaryNav />
                </>
            )}

            <Main>{children}</Main>
            {!isPathFactory && <Footer />}
        </div>
    );
};

export default Layout;
