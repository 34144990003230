export const productToLogo: { [key: string]: string } = {
    Atlas: 'atlas_product_family',
    Documentation: 'general_action_read',
    University: 'general_content_learn',
    Community: 'general_content_community',
    MongoDB: 'mdb_database',
    Realm: 'realm_product_family',
    'Realm (Mobile)': 'realm_product_family',
    Compass: 'mdb_compass',
    'Cloud Manager': 'atlas_cloud_manager',
    'Ops Manager': 'mdb_ops_manager',
    SQL: 'connectors_sql_connector',
    'Multi-Cloud': 'cloud_multicloud',
    'Data API': 'atlas_dataapi',
    Monitoring: 'general_features_realtime',
    'Online Archive': 'atlas_online_archive',
    Search: 'atlas_full_text_search',
    Charts: 'atlas_charts',
    Triggers: 'atlas_triggers',
    'Data Lake': 'atlas_data_lake',
    'Data Federation': 'atlas_data_federation',
    API: 'general_features_api',
    'Data Modeling': 'mdb_document_model',
    'Change Streams': 'mdb_change_streams',
    'Aggregation Framework': 'mdb_aggregation_pipelines',
    CRUD: 'mdb_document_model',
    Transactions: 'general_features_transactions',
    'Time series': 'mdb_time_series',
    Security: 'general_security',
    'Data Visualization': 'mdb_schema_visualization',
    Shell: 'mdb_shell',
    SDK: 'realm_sdk',
    'Realm Sync': 'realm_sync',
    Migrator: 'mdb_migrator',
    Connectors: 'connectors',
    'Kafka Connector': 'connectors_kafka_connector',
    'BI Connector': 'connectors_bi_connector',
    CLI: 'atlas_cli',
    Sync: 'atlas_asymmetric_sync',
    Encryption: 'general_security_encryption',
    Sharding: 'mdb_sharding',
    Schema: 'mdb_schema_visualization',
    Indexes: 'mdb_wildcard_index',
    Studio: 'realm_mobile',
    'Stream Processing': 'atlas_stream_processing',
    'App Services': 'atlas_product_family',
};
