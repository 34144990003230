// Update this as more languages are introduced
import {
    AvailableLocales,
    Locale,
} from '@mdb/consistent-nav/dist/types/src/types';
import { SortByType } from '../components/search/types';

export const AVAILABLE_LANGUAGES = [
    { language: 'English', code: 'en-us' },
    { language: 'Português', code: 'pt-br' },
];

const validateCode = (potentialCode: string) =>
    !!AVAILABLE_LANGUAGES.find(({ code }) => potentialCode === code);

export const isBrowser = typeof window !== 'undefined';

/**
 * Returns the locale code based on the current location pathname of the page.
 * @returns {Locale}
 */
export const getCurrLocale = () => {
    const defaultLang = 'en-us';

    if (!isBrowser) {
        return defaultLang;
    }
    // Example on https://www.mongodb.com/pt-br/developer/articles:
    // expected pathname - /pt-br/developer/articles; expected locale - "pt-br"
    const pathname = window.location.pathname;
    const expectedPrefixes = ['developer'];
    const firstPathSlug = pathname.split('/', 2)[1];
    if (expectedPrefixes.includes(firstPathSlug)) {
        return defaultLang;
    }

    const slugMatchesCode = validateCode(firstPathSlug);
    return slugMatchesCode
        ? (firstPathSlug as Locale)
        : (defaultLang as Locale);
};

/**
 * Returns the newHref url based on the current url and selected locale from footer
 * @returns newHrefUrl
 */
export const constructLocaleUrl = (
    currentUrl: string,
    locale: string
): string => {
    const pathname = window.location.pathname;
    const expectedPrefixes = ['developer'];
    const firstPathSlug = pathname.split('/', 2)[1];
    if (expectedPrefixes.includes(firstPathSlug)) {
        if (locale !== 'en-us') {
            currentUrl = currentUrl.replace(
                'developer',
                locale + '/' + 'developer'
            );
        }
    } else {
        if (locale !== 'en-us') {
            currentUrl = currentUrl.replace(firstPathSlug, locale);
        } else {
            currentUrl = currentUrl.replace(firstPathSlug, '');
        }
    }

    return currentUrl;
};

export const onSelectLocale = (locale: string) => {
    if (isBrowser) {
        window.location.href = constructLocaleUrl(window.location.href, locale);
    }
};

export const enabledLocales = AVAILABLE_LANGUAGES.map(
    language => language.code as AvailableLocales
);

export const translateSortByOptions = (value: string) => {
    if (value === 'Mais recente') {
        return 'Latest' as SortByType;
    }
    if (value === 'Mais bem avaliado') {
        return 'Highest Rated' as SortByType;
    }
    return value as SortByType;
};
